import createShopUrl from '@shared/helpers/ShopUrl';

export const getShopUrl = (
  shopId: string | undefined,
  shopName: string | undefined
): string | undefined => {
  return shopId && shopName
    ? createShopUrl(
        process.env.NEXT_PUBLIC_IMPRESAPIU_BASE_URL,
        shopId,
        shopName
      )
    : undefined;
};
