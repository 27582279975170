import type { Request } from 'express';
import { extractSbtHeaders, getSingleAd } from '@sbt-web/network/server';
import { AdItem, SearchParams } from '@sbt-web/network/types';
import { searchItems } from '@sbt-web/network/client';

const fetchItem = async (
  id: string,
  environmentID: string | undefined,
  req: Request
): Promise<AdItem | null> => {
  try {
    const itemResponse = await getSingleAd(
      process.env.ANUBI_BASE_URL as string,
      id,
      {
        ...extractSbtHeaders(req),
        environmentID,
        canarySupport: 'phone_number_mask',
      }
    );
    return itemResponse.body;
  } catch (e) {
    return null;
  }
};

const searchForRecommendation = async (
  searchParams: SearchParams
): Promise<AdItem[]> => {
  try {
    const backupItemsSearch = await searchItems(
      process.env.NEXT_PUBLIC_HADES_BASE_URL,
      {
        ...searchParams,
        c: '2',
        lim: 10,
        advt: '1',
      },
      undefined,
      true
    );
    return backupItemsSearch.body.ads;
  } catch {
    return [];
  }
};

export { fetchItem, searchForRecommendation };
