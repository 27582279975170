import type { SearchResult, UserAdInfo } from '@sbt-web/network/types';
import { getUserAdInfo, searchItems } from '@sbt-web/network/client';
import { getCategoryByID, italianInItaly } from '@sbt-web/utils';
import { unwrap } from '@shared/helpers/Promise';
import { useEffect, useState } from 'react';
import type { AdInfoForUser } from './types';

interface LoadDataResponse {
  userAdInfo?: UserAdInfo;
  search?: SearchResult;
}

const GIAPETE_TIMEOUT = 3000;

const loadData = async (userId: string): Promise<LoadDataResponse> =>
  Promise.allSettled([
    searchItems(
      process.env.NEXT_PUBLIC_HADES_BASE_URL,
      {
        uid: userId,
        lim: 1,
      },
      undefined,
      true
    ),
    getUserAdInfo(
      process.env.NEXT_PUBLIC_HADES_BASE_URL,
      userId,
      GIAPETE_TIMEOUT
    ),
  ]).then(([searchResultResponse, userAdInfoResponse]) => {
    return {
      userAdInfo: unwrap(userAdInfoResponse)?.body,
      search: unwrap(searchResultResponse)?.body,
    };
  });

const createMessage = function (date?: string): string | null {
  if (!date) {
    return null;
  }

  const epochTimestamp = Date.parse(date);

  if (Number.isNaN(epochTimestamp)) {
    console.error(`The date ${date} is an invalid date`);

    return null;
  }

  const messageDate = new Date(epochTimestamp);

  const monthAndYear = messageDate.toLocaleDateString(italianInItaly, {
    year: 'numeric',
    month: 'long',
  });

  return `Pubblica da ${monthAndYear}`;
};

export function useInfo(userId: string): AdInfoForUser {
  const [infoComponent, setInfoComponent] = useState<AdInfoForUser>({
    categories: null,
    message: null,
    numberOfAdsOnline: null,
    numberOfAdsPublished: null,
  });

  useEffect(() => {
    let isRemoved = false;

    (async () => {
      const data = await loadData(userId);
      if (!isRemoved && data) {
        setInfoComponent({
          message: createMessage(data.userAdInfo?.firstAdInsertionDate),
          numberOfAdsPublished: data.userAdInfo?.totalApprovedAds ?? null,
          categories: (data.userAdInfo?.userCategoriesInfo ?? []).map(
            (catInfo) => {
              return {
                category: getCategoryByID(catInfo.category),
                approvedAds: catInfo.approvedAds,
              };
            }
          ),
          numberOfAdsOnline: data.search?.total ?? null,
        });
      }
    })();

    return () => {
      isRemoved = true;
    };
  }, [userId]);

  return infoComponent;
}
