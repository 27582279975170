import React, { useEffect } from 'react';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';
import {
  type Slot,
  type AdvertisersConfigGeneric,
  VideoAdv,
} from '@sbt-web/adv';
import style from './LazyVideoPreroll.module.scss';

/**
 * Exported only to use it in unit test
 */
export const getVideoUrl = (
  adUnitPath: string,
  sizes: googletag.MultiSize
): string => {
  const adUnitNormalized = {
    bids: [],
    code: adUnitPath,
    id: LiraContainerIds.adView.videoPreroll,
    mediaTypes: {},
    sizes,
  };

  // Generate targetings from GPT ad object
  const pubAds = window.googletag?.pubads();
  const targeting = pubAds
    .getTargetingKeys()
    .reduce((acc: Record<string, string>, key: string) => {
      const targeting = pubAds.getTargeting(key);
      acc[key] = targeting[0];
      return acc;
    }, {});

  const correlator = Math.floor(Math.random() * 10000000000).toString(); // NOSONAR
  return window.pbjs?.adServers.dfp.buildVideoUrl({
    adUnit: adUnitNormalized,
    params: {
      iu: adUnitNormalized.code,
      env: 'vp',
      gdfp_req: '1',
      sz: '624x468',
      unviewed_position_start: '1',
      tfcd: '0',
      npa: '0',
      impl: 's',
      correlator,
      output: 'vast',
      cust_params: targeting,
    },
  });
};

export const LazyVideoPreroll = function ({
  parentRef,
}: {
  parentRef: React.RefObject<HTMLElement>;
}) {
  const [video, setVideo] = React.useState<string | null>(null);

  useEffect(() => {
    window.liraSDKQueue?.push(() => {
      window.liraSdk
        .fetchLazyLoadAd([LiraContainerIds.adView.videoPreroll])
        .then((map: Map<string, Slot>) => {
          const slot = map.get(LiraContainerIds.adView.videoPreroll);
          if (!slot) {
            return;
          }
          const config =
            slot.primaryAdvertiserConfig as AdvertisersConfigGeneric<'gam'>;
          const adUnit = config.config.adUnitCode;
          const sizes = config.config.sizes;
          const videoUrl = getVideoUrl(adUnit, sizes);
          setVideo(videoUrl);
        });
    });
  }, []);

  return (
    <>
      <div
        id={LiraContainerIds.adView.videoPreroll}
        className={style['videoId']}
      />
      {video ? <VideoAdv parentRef={parentRef} videoUrl={video} /> : null}
    </>
  );
};
