import React from 'react';
import styles from './index.module.scss';

const STARS_NUMBER = 5;

const getScorePercentage = (score: number): number => (score / 5) * 100;
interface Props {
  score: number;
}

export const Stars = ({ score }: Props) => {
  return (
    <div
      className={styles.stars}
      aria-label={`Valutazione: ${score.toString()} su ${STARS_NUMBER}`}
    >
      <svg viewBox="0 0 80 16" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <path
            id="star"
            d="M10.471 5.267L8.453.964c-.118-.25-.415-.357-.665-.24-.106.05-.191.135-.24.24l-2.02 4.303-4.566.697c-.273.042-.46.297-.419.57.016.103.064.2.136.274L4 10.21l-.778 4.773c-.044.272.14.53.413.574.111.018.225-.002.323-.056L8 13.267l4.043 2.235c.242.133.546.046.68-.196.054-.098.074-.212.055-.322L12 10.21l3.32-3.403c.193-.198.19-.514-.008-.707-.075-.073-.17-.12-.274-.137l-4.567-.697z"
          />
          <clipPath id="star-clip-path">
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="#star"
            ></use>
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="#star"
              x="16"
            ></use>
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="#star"
              x="32"
            ></use>
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="#star"
              x="48"
            ></use>
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="#star"
              x="64"
            ></use>
          </clipPath>
        </defs>
        <g fill="#DFE3ED">
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#star"
          ></use>
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#star"
            x="16"
          ></use>
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#star"
            x="32"
          ></use>
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#star"
            x="48"
          ></use>
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#star"
            x="64"
          ></use>
        </g>
        <rect
          className={styles.rect}
          x="0"
          y="0"
          width={`${getScorePercentage(score).toString()}%`}
          height="100%"
          clipPath="url(#star-clip-path)"
        ></rect>
      </svg>
    </div>
  );
};
