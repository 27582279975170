import type { AdItem } from '@sbt-web/network/types';
import { socialMetaForAdItem } from '@sbt-web/social-meta-generator';
import { getCategoryThemeById, Theme } from '@shared/helpers/Themes';

interface SocialMetaProps {
  adItem: AdItem;
}

export const getVerticalLogo = function (adItem: AdItem): string {
  const theme = getCategoryThemeById(adItem.category?.id) ?? Theme.Red;
  return `${process.env.NEXT_PUBLIC_ENV_BASE_URL}/static/img/social-meta-fallback/${theme}.png`;
};

export default function SocialMeta({ adItem }: SocialMetaProps): JSX.Element[] {
  const defaultImage = getVerticalLogo(adItem);
  return socialMetaForAdItem({ adItem, fallbackImage: defaultImage });
}
