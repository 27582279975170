import React from 'react';
import { Stars } from './_components/Stars';
import { Icon } from '@sbt-web/ui';
import type { ShopGoogleReviews as ShopGoogleReviewsTypes } from '@sbt-web/network/types';
import cn from 'classnames';
import styles from './index.module.scss';

interface Props {
  data: ShopGoogleReviewsTypes | null;
  classes?: Array<string>;
}

export const ShopGoogleReviews = ({ data, classes = [] }: Props) => {
  if (!data?.shopId || !data?.rating || !data?.links?.placeUri) {
    return null;
  }

  const { rating, links } = data;
  const placeUri = links?.placeUri;

  return (
    <div className={cn(styles.container, classes)}>
      <div className={styles.rating}>
        <span className={styles.points}>{rating}</span>

        <a
          href={placeUri}
          target="_blank"
          rel="noreferrer"
          aria-label="Visualizza le recensioni su Google"
        >
          <Stars score={data.rating} />
        </a>

        <Icon name="Google" classes={[styles.googleLogo]} />
      </div>

      <a
        href={placeUri}
        target="_blank"
        rel="noreferrer"
        className={styles['poweredby']}
        aria-label="Visualizza le recensioni su Google"
      >
        Punteggio su Google
      </a>
    </div>
  );
};
