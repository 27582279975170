import React, { PropsWithChildren } from 'react';
import { Badge, Divider, Subhead } from '@sbt-web/ui';
import { getShopLabelByCategoryId } from '@sbt-web/utils';
import { DEFAULT_ADVERTISER_NAME } from '@shared/constants';
import { getAdvertiserName, isPro } from '@shared/models/Advertiser';
import { ShopLogo } from './ShopLogo';
import { ShopGoogleReviews } from '../ShopGoogleReviews';
import { ContactButton } from '../ContactUser';
import {
  PrivateUserBadgeSize,
  PrivateUserProfileBadge,
} from '@client/components/Detail/PrivateUserProfileBadge';
import { getShopUrl } from './utils/getShopUrl';
import '@sbt-web/user-rating/dist/index.css';
import ownClasses from './index.module.scss';
import classnames from 'classnames';
import type { CategoryID, ItemAdvertiser } from '@sbt-web/network/types';
import { UserDetailsProps } from './types';

export const CompanyBadge = ({ categoryId }: { categoryId: CategoryID }) => {
  const label = getShopLabelByCategoryId(categoryId);

  return label ? (
    <Badge classes={[ownClasses['company-badge']]}>{label}</Badge>
  ) : null;
};

type ShopLinkWrapper = PropsWithChildren<{
  advertiser: ItemAdvertiser;
}>;
const ShopLinkWrapper = ({ children, advertiser }: ShopLinkWrapper) => {
  const { shopName, shopId } = advertiser;
  const shopUrl = getShopUrl(shopId, shopName);

  return shopUrl ? (
    <a href={shopUrl} className={classnames(ownClasses['shop-link'])}>
      {children}
    </a>
  ) : (
    <>{children}</>
  );
};

type ShopWrapperProps = PropsWithChildren<{
  advertiser: ItemAdvertiser;
  showContactButton: boolean;
}>;
const ShopWrapper = ({
  children,
  advertiser,
  showContactButton,
}: ShopWrapperProps) => {
  const { shopName, shopId } = advertiser;
  const shopUrl = getShopUrl(shopId, shopName);

  return shopUrl ? (
    <div className={classnames(ownClasses['shop-details-wrapper'])}>
      {children}
    </div>
  ) : (
    <div className={ownClasses['shop-with-contact']}>
      <div className={ownClasses['shop-details-wrapper']}>{children}</div>
      {showContactButton && <ContactButton />}
    </div>
  );
};

export const UserDetails = ({
  advertiser,
  categoryId,
  advertiserProfile,
  shop,
  shopGoogleReviews,
  trustInfo,
  showContactButton,
}: UserDetailsProps) => (
  <>
    <Divider spacing="md" />

    {isPro(advertiser.type) ? (
      <ShopWrapper
        advertiser={advertiser}
        showContactButton={showContactButton || false}
      >
        <ShopLinkWrapper advertiser={advertiser}>
          <ShopLogo logoURL={shop?.logo?.medium?.secureuri} />
        </ShopLinkWrapper>

        <div className={ownClasses['user-details-name']}>
          <CompanyBadge categoryId={categoryId} />

          <ShopLinkWrapper advertiser={advertiser}>
            <Subhead element="p" classes={[ownClasses['user-name']]}>
              {getAdvertiserName(advertiser.type, shop, advertiserProfile)}
            </Subhead>
          </ShopLinkWrapper>

          <ShopGoogleReviews data={shopGoogleReviews} />
        </div>
      </ShopWrapper>
    ) : (
      <PrivateUserProfileBadge
        name={advertiserProfile?.username ?? DEFAULT_ADVERTISER_NAME}
        userId={advertiser.userId}
        size={PrivateUserBadgeSize.Small}
        pulseEventName="click-on-name-AD-Detail"
        picture={advertiserProfile?.picture}
        score={trustInfo?.reputation.feedback?.sourceScores.MEMBER}
        presenceStatus={
          trustInfo?.presence.enabled
            ? (trustInfo?.presence.status ?? undefined)
            : undefined
        }
        reviewCount={trustInfo?.reputation.feedback?.sourceCounts.MEMBER}
        showContactButton={showContactButton}
      />
    )}
    <Divider topSpacing="md" bottomSpacing="null" />
  </>
);

export default UserDetails;
