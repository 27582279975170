import { LiraAdvContainer } from '@sbt-web/adv';
import {
  type AdItem,
  type AdTypeKeyValue,
  CategoryID,
  type ItemCategory,
  type ItemFeature,
  type ItemGeo,
} from '@sbt-web/network/types';
import type { Pulse } from '@sbt-web/tracking';
import { getOrCreatePulse } from '@tools/tracking/utils';
import dynamic from 'next/dynamic';
import React from 'react';
import css from './style/skyscraper.module.scss';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';
import { CreditAgricoleBox } from '@sbt-web/ancillary-services';
import classnames from 'classnames';

export interface LoanProps {
  price: ItemFeature;
  title?: string;
  item: AdItem;
  landingUrl?: string;
  assetsBase: string;
  pulseInstance?: Pulse;
  houstonTrackAncillary?: (e: string) => void;
}

const TextLinkBox = dynamic(() => import('./TextLinkBox'), {
  ssr: false,
});
TextLinkBox.displayName = 'DynamicTextLinkBox';

interface Props {
  item: AdItem;
  newCreditAgricoleToggle?: boolean;
}

const asideAdv = (
  <LiraAdvContainer
    idForAd={LiraContainerIds.adView.aside}
    className={classnames(css['margin'], css['height-skyscraper'])}
  />
);

const asideAdv2 = (
  <LiraAdvContainer
    idForAd={LiraContainerIds.adView.aside2}
    className={classnames(css['margin'], css['height-box'])}
  />
);

const motoriCategories: CategoryID[] = [
  CategoryID.Motori,
  CategoryID.Auto,
  CategoryID.Moto,
  CategoryID.Nautica,
  CategoryID.CaravanECamper,
  CategoryID.VeicoliCommerciali,
];

const crediteAgricoleRealestateCategories: CategoryID[] = [
  CategoryID.TerreniRustici,
  CategoryID.UfficiLocali,
];

export const provinceCrediteAgricoleList = [
  'CE',
  'NA',
  'SA',
  'PC',
  'BO',
  'MO',
  'PR',
  'RE',
  'FE',
  'FC',
  'RA',
  'RN',
  'GO',
  'PN',
  'TS',
  'UD',
  'FR',
  'LT',
  'RI',
  'RM',
  'VT',
  'GE',
  'IM',
  'SP',
  'SV',
  'BG',
  'CO',
  'LC',
  'MI',
  'MB',
  'VA',
  'BS',
  'CR',
  'LO',
  'MN',
  'PV',
  'SO',
  'AN',
  'FM',
  'MC',
  'PU',
  'AL',
  'AT',
  'BI',
  'CN',
  'NO',
  'TO',
  'VB',
  'VC',
  'AG',
  'CL',
  'CT',
  'EN',
  'ME',
  'PA',
  'RG',
  'SR',
  'TP',
  'AR',
  'FI',
  'GR',
  'LI',
  'LU',
  'MS',
  'PI',
  'PT',
  'PO',
  'SI',
  'BZ',
  'TN',
  'PG',
  'TR',
  'AO',
  'BL',
  'PD',
  'RO',
  'TV',
  'VE',
  'VR',
  'VI',
];

export const shouldRenderAgosBox = ({
  adType,
  category,
}: {
  adType: AdTypeKeyValue;
  category: ItemCategory;
}): boolean =>
  motoriCategories.includes(category.id) &&
  adType.value.toLocaleLowerCase() === 'in vendita';

const shouldRenderCreditAgricoleBox = ({
  adType,
  category,
  geo,
  price,
}: {
  adType: AdTypeKeyValue;
  category: ItemCategory;
  geo: ItemGeo;
  price?: ItemFeature;
}): boolean =>
  !!price &&
  price.values?.[0] &&
  Number.parseInt(price?.values?.[0].key, 10) >= 40000 &&
  Number.parseInt(price?.values?.[0].key, 10) <= 2000000 &&
  geo.city?.shortName !== undefined &&
  provinceCrediteAgricoleList.includes(geo.city.shortName) &&
  crediteAgricoleRealestateCategories.includes(category.id) &&
  adType.value.toLocaleLowerCase() === 'in vendita';

export function getLoanBoxTitle(category: ItemCategory) {
  if (category.id === CategoryID.Auto) {
    return 'la tua auto';
  } else if (category.id === CategoryID.Moto) {
    return 'la tua moto';
  } else if (category.id === CategoryID.Nautica) {
    return 'la tua imbarcazione';
  } else if (category.id === CategoryID.CaravanECamper) {
    return 'il tuo camper';
  } else if (category.id === CategoryID.VeicoliCommerciali) {
    return 'il tuo veicolo';
  } else {
    return '';
  }
}

export const Skyscraper: React.FunctionComponent<Props> = ({
  item,
  newCreditAgricoleToggle,
}) => {
  const category = item.category;
  const adType = item.type;
  const advertiserType = item.advertiser.type;
  const price = item.features['/price'];

  return (
    <>
      {advertiserType === 1 ? (
        <div id="pro_profile">
          {asideAdv}
          {asideAdv2}
        </div>
      ) : (
        <>
          {asideAdv}
          {asideAdv2}
        </>
      )}
      {shouldRenderCreditAgricoleBox({
        category,
        adType,
        geo: item.geo,
        price,
      }) && !newCreditAgricoleToggle ? (
        <CreditAgricoleBox
          item={item}
          price={price}
          assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE_URL}
          pulseInstance={getOrCreatePulse()}
        />
      ) : null}

      <TextLinkBox item={item} />
    </>
  );
};

Skyscraper.displayName = 'Skyscraper';

export default Skyscraper;
