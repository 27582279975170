import React, { useEffect, useState } from 'react';
import type { AdItem } from '@sbt-web/network/types';
import { ButtonLink, Icon } from '@sbt-web/ui';
import DetailBreadcrumbs from '@client/components/SEO/Breadcrumbs/DetailBreadcrumbs';
import { getOrCreatePulse } from '@tools/tracking/utils';
import { mapDetailToPageInfo } from '@tools/search';
import getNavigationData, {
  type SearchNavigationDestinations,
} from '@client/bridges/navigation-data';
import css from './index.module.scss';

interface Props {
  item: AdItem;
}

const trackBackClick = () => {
  getOrCreatePulse()?.queueEvent({
    type: 'Click',
    object: {
      type: 'UIElement',
      '@id': 'sdrn:subito:ad_detail:navigation-bar:element:back-button',
      name: 'Back to Listing Button',
      elementType: 'Button',
    },
    page: {
      pageType: 'ad_detail',
      '@id': 'sdrn:subito:page:ad_detail',
      '@type': 'Page',
    },
  });
};

export const NavigationRow = ({ item }: Props) => {
  const [navInfo, setNavInfo] = useState<SearchNavigationDestinations>({
    listingUrl: null,
  });

  useEffect(() => {
    getNavigationData(item.urn).then(setNavInfo);
  }, [item.urn]);

  return (
    <nav className={css.navigation}>
      <ButtonLink
        href={navInfo.listingUrl ?? mapDetailToPageInfo(item).url}
        onClick={trackBackClick}
        design="outline"
        size="small"
        icon={<Icon name="ArrowLeft" />}
      >
        Torna alla ricerca
      </ButtonLink>

      <DetailBreadcrumbs item={item} />
    </nav>
  );
};

export default NavigationRow;
