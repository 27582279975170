import type { ProfileQueryData } from '@sbt-web/networking';
import React from 'react';
import { AdvertiserInfoPrivate } from './AdvertiserInfoPrivate';
import { AdvertiserInfoPro } from './AdvertiserInfoPro';
import {
  type TrustProfileInfo,
  type Shop,
  type ShopGoogleReviews,
  type AdItem,
  AdvertiserType,
} from '@sbt-web/network/types';

interface Props {
  item: AdItem;
  shop: Shop | null;
  shopGoogleReviews: ShopGoogleReviews | null;
  advertiserProfile: ProfileQueryData | null;
  trustInfo: TrustProfileInfo | null;
  showContactButton?: boolean;
}

const AdvertiserInfo: React.FunctionComponent<Props> = ({
  shop,
  shopGoogleReviews,
  advertiserProfile,
  trustInfo,
  item,
  showContactButton = false,
}) => {
  switch (item.advertiser.type) {
    case AdvertiserType.Private:
    case AdvertiserType.Company:
      return (
        <AdvertiserInfoPrivate
          advertiserProfile={advertiserProfile}
          trustInfo={trustInfo}
          item={item}
          showContactButton={showContactButton}
        />
      );
    case AdvertiserType.Pro:
      return (
        <AdvertiserInfoPro
          shop={shop}
          shopGoogleReviews={shopGoogleReviews}
          advertiserProfile={advertiserProfile}
          item={item}
        />
      );
    default:
      return null;
  }
};

export default AdvertiserInfo;
