import type { Feature } from '@client/components/Detail/FeatureList';
import { ItemFeature, ItemGeo } from '@sbt-web/network/types';
import { isPack, mapPackFeatures } from '@shared/helpers/PackFeatures';
import { filterDetailFeatures } from '@shared/models';

const mapFlatFeatures = (features: ItemFeature[]): Feature[] =>
  features
    .filter((f) => !isPack(f))
    .map((f) => ({
      label: f.label,
      value: f.values?.map((v) => v?.value).join(', '),
      uri: f.uri,
    }));

const mapGeoToFeatures = (geo?: ItemGeo): Feature[] => {
  if (!geo?.zone) return [];

  // add zone to feature list
  return [
    {
      label: geo.zone.label,
      // replacing "/" with ", " to allow for line breaks
      value: geo.zone.value.replace(/\//g, ', '),
      uri: geo.zone.uri,
    },
  ];
};

const flattenFeatures = (
  features: Record<string, ItemFeature>,
  geo?: ItemGeo
): Feature[] => {
  const itemFeatures = Object.keys(features).map((f) => features[f]);

  const allowedFeatures = filterDetailFeatures(itemFeatures);

  const allFeatures = [
    ...mapPackFeatures(allowedFeatures),
    ...mapFlatFeatures(allowedFeatures),
    ...mapGeoToFeatures(geo),
  ];

  return allFeatures;
};

export default flattenFeatures;
