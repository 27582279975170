import { getMacroOfCategoryID } from '@sbt-web/utils';
import {
  type AdItem,
  AdTypes,
  CategoryID,
  FeatureURI,
} from '@sbt-web/network/types';
import { evalBoolFeature } from '@shared/models';

export enum Copy {
  NoAgenciesNoTlmk = 'Non voglio essere contattato da agenzie o telemarketing',
  JobOfferDisclaimer = 'Il presente annuncio è rivolto ad entrambi i sessi, ai sensi delle leggi 903/77 e 125/91, e a persone di tutte le età e tutte le nazionalità, ai sensi dei decreti legislativi 215/03 e 216/03.',
  OriginalItem = 'Il venditore dichiara che il bene oggetto del presente annuncio è originale.',
}

const originalItemCategories: CategoryID[] = [
  CategoryID.AbbigliamentoAccessori,
  CategoryID.Sports,
  CategoryID.TuttoBambini,
];

export const getCopy = (item: AdItem): string | null => {
  if (!item.category) {
    return null;
  }

  const categoryId = item.category.id;
  if (categoryId === CategoryID.OfferteLavoro) {
    return Copy.JobOfferDisclaimer;
  }

  if (
    originalItemCategories.includes(categoryId) &&
    // We do not show this disclaimer for Cercasi ads
    item.type.key !== AdTypes.Seek
  ) {
    return Copy.OriginalItem;
  }

  const macro = getMacroOfCategoryID(categoryId);
  if (macro.id === CategoryID.Immobili) {
    const noSalesmanFeature = item.features[FeatureURI.NoSalesman];
    if (evalBoolFeature(noSalesmanFeature)) {
      return Copy.NoAgenciesNoTlmk;
    }
  }

  return null;
};
