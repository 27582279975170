import type { AdItem } from '@sbt-web/network/types';
import classnames from 'classnames';
import React from 'react';
import AdDescription from '../../AdDescription';
import type { SectionProps } from './section';

interface Props {
  ad: AdItem;
}

const AdDescriptionSection: React.FC<Props & SectionProps> = ({
  ad,
  classes = [],
}) => (
  <section className={classnames(...classes)}>
    <AdDescription ad={ad} />
  </section>
);

export default AdDescriptionSection;
