import {
  AdvSponsorshipImage,
  AdvSponsorshipOverlay,
  useAdvGalleryPlacement,
} from '@sbt-web/adv';
import { Carousel as CarouselLibrary } from '@sbt-web/carousel';
import { useViewport } from '@sbt-web/hooks';
import type { AdItem, ItemCategory, ItemImage } from '@sbt-web/network/types';
import { Caption } from '@sbt-web/ui';
import classnames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type FunctionComponent,
} from 'react';
import Placeholder from './Placeholder';
import CarouselCell from './CarouselCell';

import ownClasses from './index.module.scss';

interface Props {
  images: ItemImage[];
  alt: string;
  category: ItemCategory;
  currentIndex: number;
  onChange: (index: number) => void;
  reduceDataUse?: boolean;
  goToAdv?: boolean;
  setGoToAdv?: (v: boolean) => void;
  ad?: AdItem;
  switchFullscreen?: () => void;
  fullScreen?: boolean;
}

// Safari doesn't support lazy loading yet, so we only use eager loading.
// Otherwise the image only loads when scrolled to and it feels slow.
// iPhones and Macs are powerful devices that will be able to handle
// the extra load.
const isLikelyOnSafari =
  // This will render with false on the server,
  // and then switch and download the images eagerly only on Safari.
  typeof window !== 'undefined' && 'safari' in window;

const Carousel: FunctionComponent<Props> = ({
  reduceDataUse,
  images,
  alt,
  currentIndex,
  onChange,
  category,
  goToAdv = false,
  setGoToAdv,
  ad,
  switchFullscreen,
  fullScreen,
}) => {
  const { isDesktop } = useViewport();
  const { advLoaded } = useAdvGalleryPlacement();

  const [carouselIndex, setCarouselIndex] = useState(currentIndex);

  const [shouldSkipLazyLoading, setShouldSkipLazyLoading] = useState(false);
  useEffect(() => {
    if (isLikelyOnSafari) {
      setShouldSkipLazyLoading(true);
    }
  }, []);

  // To keep the FID and INP low, we do not render the buttons
  // until the JS to handle the clicks is ready.
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const cells = useMemo(
    () =>
      images
        .filter((img) => img?.cdnBaseUrl != null)
        .map((image, index) => (
          <CarouselCell
            key={image.cdnBaseUrl}
            index={index}
            alt={alt}
            image={image}
            isDesktop={isDesktop}
            fullScreen={fullScreen}
            reduceDataUse={reduceDataUse}
            shouldSkipLazyLoading={shouldSkipLazyLoading}
            switchFullscreen={switchFullscreen}
          />
        ))
        // Add ADV slide:
        .concat(
          <AdvSponsorshipImage
            key="adv-slide"
            gallerySizes={images.length}
            advLoaded={advLoaded}
            ad={ad}
          />
        ),
    [
      images,
      advLoaded,
      ad,
      isDesktop,
      reduceDataUse,
      alt,
      shouldSkipLazyLoading,
      fullScreen,
      switchFullscreen,
    ]
  );

  /**
   * Handle onChange swiffy
   */
  const onChangeSlide = useCallback(
    (index: number) => {
      if (index < images.length) {
        onChange(index);
        if (setGoToAdv) {
          setGoToAdv(false);
        }
      } else if (index == images.length) {
        if (setGoToAdv) {
          setGoToAdv(true);
        }
      }
    },
    [images.length, onChange, setGoToAdv]
  );

  /**
   * When goToAdv set to true, move carousel toward the ADV slide
   */
  useEffect(() => {
    if (goToAdv) {
      setCarouselIndex(cells.length - 1);
    }
  }, [cells.length, goToAdv]);

  /**
   * When goToAdv set to false, back to currentIndex
   */
  useEffect(() => {
    if (!goToAdv) {
      setCarouselIndex(currentIndex);
    }
  }, [currentIndex, goToAdv]);

  if (images.length === 0) {
    return <Placeholder categoryId={category.id} />;
  }

  return (
    <>
      {goToAdv ? null : (
        <Caption size="small" classes={[ownClasses.counter]}>
          {`${currentIndex + 1}/${images.length}`}
        </Caption>
      )}

      <CarouselLibrary
        cssClass={classnames(ownClasses['carousel'], {
          [ownClasses['full-screen-carousel']]: fullScreen,
        })}
        labelArrows={{
          right: 'Vai alla prossima immagine',
          left: "Vai all'immagine precedente",
        }}
        listItems={cells}
        currentIndex={carouselIndex}
        onChange={onChangeSlide}
        showButtons={isMounted && (advLoaded || images.length > 1)}
        overlayElement={
          <AdvSponsorshipOverlay
            imageArray={images.length}
            goToAdv={goToAdv}
            advLoaded={advLoaded}
          />
        }
      />
    </>
  );
};

export default Carousel;
