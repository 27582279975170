import { useIsVisible } from '@sbt-web/hooks';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';
import { LiraAdvContainer } from '@sbt-web/adv';
import React, { useCallback, useEffect, useRef } from 'react';

const observerOptions = {
  threshold: 0.01,
  rootMargin: '200px',
};

export const LiraLazySlotBelowAdView = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isVisible, setElement, unobserve] = useIsVisible({ observerOptions });

  // Attach the observer to the element
  useEffect(() => {
    if (elementRef.current) {
      setElement(elementRef.current);
    }
    return () => {
      unobserve(); // Clean up observer when the component unmounts
    };
  }, [setElement, unobserve]);

  // Handle lazy loading logic
  const handleLazyLoad = useCallback(() => {
    if (isVisible) {
      window.liraSDKQueue.push(() => {
        window.liraSdk.fetchLazyLoadAd([LiraContainerIds.adView.bottom]);
      });
      unobserve(); // Clean up observer when the component unmounts
    }
  }, [isVisible, unobserve]);

  useEffect(() => {
    handleLazyLoad();
  }, [handleLazyLoad]);

  return (
    <div ref={elementRef}>
      <LiraAdvContainer
        idForAd={LiraContainerIds.adView.bottom}
        style={{
          display: 'block',
        }}
      />
    </div>
  );
};
