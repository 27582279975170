import { Caption, Headline6, Subhead } from '@sbt-web/ui';
import React from 'react';
import classes from './index.module.scss';
import { CategoryInfoForUserAd } from '../../types';

type UserAdsDetailProps = {
  totalAds: number;
  categories: Array<CategoryInfoForUserAd>;
};

const UserAdsDetail = ({ totalAds, categories }: UserAdsDetailProps) => (
  <section>
    <Headline6 weight="book" classes={[classes.title]}>
      Annunci totali: <b>{totalAds}</b>
    </Headline6>

    <ol className={classes['category-list']}>
      {categories.map((c) => (
        <li
          className={classes['user-ads-detail__category']}
          key={c.category.id}
        >
          <Subhead
            classes={[classes['category-name']]}
            size="small"
            element="p"
          >
            {c.category.label}
          </Subhead>

          <div className={classes['category-row']}>
            <img
              alt="category"
              width={24}
              height={24}
              src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/static/icons/categories/${c.category.id}.svg`}
            />
            <div
              aria-hidden="true"
              className={classes['category-bar']}
              style={{
                width: `${Math.round((c.approvedAds / totalAds) * 100)}%`,
              }}
            />

            <Caption size="small">{c.approvedAds}</Caption>
          </div>
        </li>
      ))}
    </ol>
  </section>
);

export default UserAdsDetail;
