import { getUserProfileInfo, updateUserProfile } from '@sbt-web/network/client';
import { NetError, type UserProfileInfo } from '@sbt-web/network/types';
import { HTTPStatusCode, PolymatheiaClient } from '@sbt-web/networking';
import { WEB_API_CHANNEL } from '@shared/constants';

export const polymatheiaClient = new PolymatheiaClient(
  process.env.NEXT_PUBLIC_HADES_BASE_URL,
  WEB_API_CHANNEL
);

function validateNameClient(name = ''): string | undefined {
  if (name.length === 0) {
    return UPDATE_USERNAME_ERRORS_HANDLED['POLYMATHEIA:empty-user-name'];
  }
  if (name.length < 2) {
    return UPDATE_USERNAME_ERRORS_HANDLED['POLYMATHEIA:name-too-short'];
  }
}

export const UPDATE_USERNAME_ERRORS_HANDLED = {
  'POLYMATHEIA:bad-user-name': 'Parole non consentite',
  'POLYMATHEIA:empty-user-name': 'Inserisci il tuo nome',
  'POLYMATHEIA:name-too-short':
    'Il tuo nome deve avere tra i 2 e i 50 caratteri',
  'POLYMATHEIA:name-too-long':
    'Il tuo nome deve avere tra i 2 e i 50 caratteri',
};
const DEFAULT_UPDATE_USERNAME_ERROR_MESSAGE =
  'Si è verificato un errore imprevisto, si prega di riprovare.';

export const updateUsername = async (
  userId: string,
  username: string
): Promise<string | undefined> => {
  const clientMsg = validateNameClient(username);
  if (clientMsg) {
    return clientMsg;
  }

  try {
    await updateUserProfile(
      process.env.NEXT_PUBLIC_HADES_BASE_URL,
      userId,
      { username },
      'patch'
    );
  } catch (error) {
    if (error instanceof NetError) {
      const errors = error.cause.errors as Array<{ error_code: string }>;
      const errorCode = errors[0]
        .error_code as keyof typeof UPDATE_USERNAME_ERRORS_HANDLED;

      return (
        UPDATE_USERNAME_ERRORS_HANDLED[errorCode] ||
        DEFAULT_UPDATE_USERNAME_ERROR_MESSAGE
      );
    }

    return DEFAULT_UPDATE_USERNAME_ERROR_MESSAGE;
  }
};

export const getUsername = async (
  userId: string
): Promise<UserProfileInfo | undefined> => {
  try {
    const response = await getUserProfileInfo(
      process.env.NEXT_PUBLIC_HADES_BASE_URL,
      userId
    );

    if (response.status === HTTPStatusCode.OK) {
      return response.body;
    }
  } catch (error) {
    return undefined;
  }
};
