import { useBreadcrumbForAd } from '@sbt-web/breadcrumbs';
import { AdItem, ItemGeo } from '@sbt-web/network/types';
import { TuttaItalia } from '@sbt-web/networking';
import {
  adTypesLabelsMap,
  adTypesToSlugMap,
} from '@shared/tools/search/values';
import React from 'react';

export interface Props {
  item: AdItem;
}

const DetailBreadcrumbs: React.FunctionComponent<Props> = ({ item }) => {
  const adType = item.type.key;
  let selectedGeo: ItemGeo | undefined = item.geo;
  if (selectedGeo.region.id === TuttaItalia.id) {
    selectedGeo = undefined;
  }

  const { breadcrumbs } = useBreadcrumbForAd({
    adTypeData: {
      id: adType,
      label: adTypesLabelsMap[adType],
      parameterName: adTypesToSlugMap[adType],
    },
    assetsBase: process.env.NEXT_PUBLIC_ASSETS_BASE_URL,
    origin: process.env.NEXT_PUBLIC_ENV_BASE_URL,
    categoryId: item.category.id,
    geo: selectedGeo,
  });

  return breadcrumbs;
};

export default DetailBreadcrumbs;
