import React from 'react';
import type { AdItem } from '@sbt-web/network/types';
import type { PublicUser } from '@sbt-web/auth';
import { FullModal } from '@sbt-web/ui';

import type { SearchData } from '../../types';
import { Form } from './form';

interface ContactFormProps {
  isMobile: boolean;
  onCloseContactForm: () => void;
  isPro: boolean;
  category: string;
  enableAttachments: boolean;
  userId: string;
  advertiserName: string;
  subject: string;
  adUrn: string;
  requestLogin: () => Promise<PublicUser>;
  isModalOpened: boolean;
  item: AdItem;
  onSendReplyCallback?: () => Promise<void>;
  buyerName?: string;
  search?: SearchData;
}

export const ContactForm = ({
  isMobile,
  onCloseContactForm,
  isPro,
  category,
  enableAttachments,
  userId,
  advertiserName,
  subject,
  adUrn,
  onSendReplyCallback,
  requestLogin,
  isModalOpened,
  buyerName,
  search,
  item,
}: ContactFormProps) => {
  const FormComponent = (
    <Form
      isMobile={isMobile}
      onCloseContactForm={onCloseContactForm}
      isPro={isPro}
      category={category}
      enableAttachments={enableAttachments}
      userId={userId}
      advertiserName={advertiserName}
      buyerName={buyerName}
      subject={subject}
      adUrn={adUrn}
      onSendReplyCallback={onSendReplyCallback}
      requestLogin={requestLogin}
      search={search}
      item={item}
    />
  );

  return isMobile ? (
    <FullModal
      title="Contatta l'utente"
      open={isModalOpened}
      onClose={onCloseContactForm}
    >
      {FormComponent}
    </FullModal>
  ) : (
    FormComponent
  );
};
