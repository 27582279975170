import ErrorBoundary from '@sbt-web/error-boundary';
import type { AdItem } from '@sbt-web/network/types';
import type { RelatedAdTrackingInfo } from '@sbt-web/networking';
import {
  RelatedAds as RelatedAdsComponent,
  RelatedAdsProps,
} from '@sbt-web/related-ads';
import { isPro } from '@shared/models/Advertiser';
import { PULSE_SCHEMA_VERSION } from '@tools/tracking/constants';
import { getOrCreatePulse } from '@tools/tracking/utils';
import { HADES_PATH } from '@shared/constants';
import React from 'react';
import type { OptionalSectionProps } from './section';
import { useOptionalSection } from './utils';

import '@sbt-web/related-ads/style';

const getItemId = function (urn: string) {
  return `sdrn:subito:classified:${urn}`;
};

const fallbackId = 'sdrn:schibsted:environment:undefined';

export const getTrackingId = async (): Promise<string> => {
  const pulse = getOrCreatePulse();
  if (pulse) {
    const id = await pulse.getEnvironmentId();
    //To avoid status 400 from Adevinta web service, instead of
    //empty string send undefined
    if (id !== '') {
      return `sdrn:schibsted:environment:${id}`;
    } else {
      return fallbackId;
    }
  } else {
    return fallbackId;
  }
};

const commonTrackingConfig = function (
  adItem: AdItem,
  trackingInfo?: RelatedAdTrackingInfo
) {
  return {
    recommendation: {
      '@id': trackingInfo?.transactionId,
      '@type': 'RecommendationMetadata',
      recommendationType: 'related-items',
      listName: trackingInfo?.listName,
      source: 'ad-view-detail',
      queryParams: {
        itemId: getItemId(adItem.urn),
      },
    },
    schema: PULSE_SCHEMA_VERSION,
  };
};

export const trackImpression = function (
  adItem: AdItem,
  adItems: AdItem[],
  type: string,
  name: string,
  trackingInfo?: RelatedAdTrackingInfo
) {
  const data = {
    type,
    name,
    object: {
      '@type': 'RecommendationList',
      '@id': getItemId(adItem.urn),
      numItems: adItems.length,
      items: adItems.map((itm, index) => ({
        '@id': getItemId(itm.urn),
        '@type': 'RecommendationItem',
        rank: index + 1,
      })),
    },
    ...commonTrackingConfig(adItem, trackingInfo),
  };
  getOrCreatePulse()?.queueEvent(data);
  return data;
};

export const trackClick = function (
  adItem: AdItem,
  adClickedUrn: string,
  index: number,
  trackingInfo?: RelatedAdTrackingInfo
) {
  const data = {
    type: 'Click',
    name: 'Recommendation widget clicked',
    ...commonTrackingConfig(adItem, trackingInfo),
    object: {
      '@id': getItemId(adClickedUrn),
      '@type': 'RecommendationItem',
      rank: index + 1,
    },
  };
  getOrCreatePulse()?.queueEvent(data);
  return data;
};

export const trackAutoClick = function (
  adItem: AdItem,
  adClickedUrn: string,
  index: number,
  trackingInfo: RelatedAdTrackingInfo
) {
  const data = {
    type: 'Click',
    name: 'Recommendation widget clicked',
    ...commonTrackingConfig(adItem, trackingInfo),
    object: {
      '@id': getItemId(adClickedUrn),
      '@type': 'RecommendationItem',
      rank: index + 1,
      name: 'Click on Subito Recommender',
    },
  };
  getOrCreatePulse()?.queueEvent(data);
  return data;
};

type Props = OptionalSectionProps & {
  item: AdItem;
  className?: string;
};

export const RelatedAds = ({ className, item, onActualRender }: Props) => {
  const [render, setRender] = React.useState(false);
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [relatedAds, setRelatedAds] = React.useState<AdItem[]>([]);
  useOptionalSection(render && relatedAds.length > 0, onActualRender);
  const trackingIdPromise = React.useMemo(() => getTrackingId(), []);
  const onLoaded = React.useCallback(
    (items: AdItem[], trackingInfo?: RelatedAdTrackingInfo) => {
      setIsEmpty(items.length === 0);
      setRelatedAds(items);
      trackImpression(
        item,
        items,
        'View',
        'Recommendation widget impression',
        trackingInfo
      );
    },
    [item]
  );
  const onVisible = React.useCallback(
    (items: AdItem[], trackingInfo?: RelatedAdTrackingInfo) =>
      trackImpression(
        item,
        items,
        'Engagement',
        'Recommendation widget viewable impression',
        trackingInfo
      ),
    [item]
  );
  const onClick = React.useCallback(
    (
      itemClicked: { urn: string },
      index: number,
      trackingInfo?: RelatedAdTrackingInfo
    ) => trackClick(item, itemClicked.urn, index, trackingInfo),
    [item]
  );

  React.useEffect(() => {
    let destroyed = false;
    if (isPro(item.advertiser.type)) {
      setRender(false);
    } else {
      (async () => {
        const trackingId = await trackingIdPromise;
        if (!destroyed && trackingId) {
          setRender(true);
        }
      })();
    }
    return () => {
      destroyed = true;
    };
  }, [item, trackingIdPromise]);

  /**
   * useOptionalSection hook causes a renderer when first param changed.
   * Re rendering RelatedAdsComponent, it calls more than once the related ads api
   */
  return React.useMemo(() => {
    return render && !isEmpty ? (
      <section className={className}>
        <RelatedAdsComponent
          onClick={onClick}
          onLoaded={onLoaded as RelatedAdsProps['onLoaded']}
          onVisible={onVisible as RelatedAdsProps['onVisible']}
          urn={item.urn}
          userId={trackingIdPromise}
          apiBase={HADES_PATH}
          useWorker={true}
        />
      </section>
    ) : null;
  }, [
    className,
    isEmpty,
    item.urn,
    onClick,
    onLoaded,
    onVisible,
    render,
    trackingIdPromise,
  ]);
};

RelatedAds.displayName = 'RelatedAds';

const WrappedRelatedAds: React.FC<Props> = ({
  item,
  onActualRender,
  className,
}) => {
  return (
    <ErrorBoundary>
      <RelatedAds
        item={item}
        onActualRender={onActualRender}
        className={className}
      />
    </ErrorBoundary>
  );
};

export default WrappedRelatedAds;
