import { type AdItem, FeatureURI } from '@sbt-web/network/types';
import { isShippable } from '@sbt-web/utils';

import {
  isFullShipping,
  isSecurepayShipping,
} from 'server/detail-requests/ShippingCost/FeatureHelper';
import { evalNumberFeature } from '@shared/models';
import { isPro } from '@shared/models/Advertiser';

const LIMIT_EUR = 2000;

type VisibilityChecker = (ad: AdItem, userId?: string | null) => boolean;

export enum TransactionFlow {
  SendOffer = 'SENDOFFER',
  BuyNow = 'BUYNOW',
}

type TransactionFlowsChecker = (
  ad: AdItem,
  userId?: string | null
) => TransactionFlow[];

const isSold = (ad: AdItem): boolean => {
  const trxStatus = ad.features[FeatureURI.TransactionStatus]?.values[0].value;
  return !!trxStatus;
};

const canBeTransacted = (ad: AdItem): boolean => isShippable(ad) && !isSold(ad);

const isTransactionEnabled: VisibilityChecker = (ad, userId) => {
  const isUserTheAdvertiser = userId == ad.advertiser.userId;
  return canBeTransacted(ad) && !isUserTheAdvertiser;
};

const isOfferAllowed: VisibilityChecker = (ad) => !isPro(ad.advertiser.type);

const isBuyNowAllowed: VisibilityChecker = (ad) => {
  const price = evalNumberFeature(ad.features[FeatureURI.Price]);
  const isValidPrice = !!price && price > 0 && price <= LIMIT_EUR;
  return isValidPrice;
};

export const getTransactionFlows: TransactionFlowsChecker = (ad, userId) => {
  const transactionFlows: TransactionFlow[] = [];

  if (isTransactionEnabled(ad, userId)) {
    if (isOfferAllowed(ad)) transactionFlows.push(TransactionFlow.SendOffer);
    if (isBuyNowAllowed(ad)) transactionFlows.push(TransactionFlow.BuyNow);
  }
  return transactionFlows;
};

export const isPaypalEnabled = (ad: AdItem): boolean =>
  canBeTransacted(ad) &&
  (isFullShipping(ad) ||
    (isPro(ad.advertiser.type) && isSecurepayShipping(ad)));
