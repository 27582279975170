import { AdReplyButtons } from '@client/components/Detail/AdReply/components/AdReplyButtons';
import { IncreaseVisibilityButton } from '@client/components/Detail/IncreaseVisibilityButton';
import ErrorBoundary from '@sbt-web/error-boundary';
import type { AdItem } from '@sbt-web/network/types';
import { FeatureURI } from '@sbt-web/network/types';
import { BodyText } from '@sbt-web/ui';
import { wholeNumber } from '@sbt-web/utils';
import { getTransactionFlows } from '@shared/tools/transaction/utils';
import { getShowPhone } from '@tools/phoneNumber/utils';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { AdBuyEvent } from '../AdBuy';
import { AdBuyButtons } from '../AdBuy/components/AdBuyButtons';
import useUser from '../../../../client/contexts/UserContext';

import classes from './index.module.scss';

interface Props {
  item: AdItem;
}

const StickyBar = ({ item }: Props) => {
  const advertiserId = item.advertiser.userId;
  const { urn, subject } = item;
  const [stickyHide, setStickyHide] = useState(false);
  const { user } = useUser();

  const userID = user?.id;

  const isMySelf = advertiserId === userID;

  const showPhone = getShowPhone(item, userID);

  const priceFeature = item.features[FeatureURI.Price];
  const price = priceFeature
    ? wholeNumber.format(parseFloat(priceFeature.values[0].value))
    : null;

  const dispatchEventAndHideSticky = (eventName: string) => {
    window.dispatchEvent(new CustomEvent(eventName));
    setStickyHide(true);
  };

  const handleTriggerAdOffer = () => {
    dispatchEventAndHideSticky(AdBuyEvent.OpenOfferDialog);
  };

  const handleTriggerAdBuyNow = () => {
    dispatchEventAndHideSticky(AdBuyEvent.OpenCartDialog);
  };

  useEffect(() => {
    if (!window.IntersectionObserver) return;

    const anchor = document.querySelector('.sticky-cta-bottom-anchor');
    if (!anchor) return;

    const observer = new window.IntersectionObserver(([entry]) => {
      setStickyHide(
        entry.isIntersecting ||
          (!entry.isIntersecting && entry.boundingClientRect.bottom <= 0)
      );
    });

    observer.observe(anchor);

    return () => observer.disconnect();
  }, []);

  const transactionFlows = getTransactionFlows(item, userID);
  const thereIsPlaceForContactButton = transactionFlows.length < 2;
  const isOnlyOneTransactionFlowsVisible = transactionFlows.length === 1;

  return (
    <ErrorBoundary>
      <div
        id="sticky-cta-container"
        className={classnames(classes.stickyContainer, {
          [classes.hide]: stickyHide,
        })}
      >
        <div className={classes.stickyContent}>
          <div className={classes.nameAndPrice}>
            <BodyText weight="semibold">{subject}</BodyText>
            {price && <BodyText weight="semibold">{price}</BodyText>}
          </div>
          {isMySelf ? (
            <IncreaseVisibilityButton isMobile={false} adUrn={urn} />
          ) : (
            <>
              {thereIsPlaceForContactButton && (
                <AdReplyButtons
                  showPhone={showPhone}
                  adUrn={urn}
                  isMobile={false}
                  item={item}
                  contactAsSecondaryButton={isOnlyOneTransactionFlowsVisible}
                />
              )}
              <AdBuyButtons
                buyNowButtonClasses={[classes.cta]}
                sendOfferButtonClasses={[classes.cta]}
                onBuyNowButtonClick={handleTriggerAdBuyNow}
                onSendOfferButtonClick={handleTriggerAdOffer}
                item={item}
                userId={userID}
              />
            </>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default StickyBar;
export type { Props };
