import { AdItem, FeatureURI, ItemFeature } from '@sbt-web/network/types';
import { isShippable, parseItalianNumber } from '@sbt-web/utils';

type ItemFeatures = Record<string, ItemFeature>;

const hasFeature = (features: ItemFeatures, uri: FeatureURI) =>
  features[uri]?.values?.[0]?.key != null;

const getFeatureKey = (features: ItemFeatures, uri: FeatureURI) =>
  features[uri]?.values?.[0]?.key ?? null;

export const isFullShipping = (ad: AdItem) =>
  isShippable(ad) &&
  hasFeature(ad.features, FeatureURI.ItemShippingType) &&
  getFeatureKey(ad.features, FeatureURI.ItemShippingType) === '0';

export const isSecurepayShipping = (ad: AdItem) =>
  isShippable(ad) &&
  hasFeature(ad.features, FeatureURI.ItemShippingType) &&
  getFeatureKey(ad.features, FeatureURI.ItemShippingType) === '1';

export const getSecurepayCost = (features: ItemFeatures) =>
  parseItalianNumber(getFeatureKey(features, FeatureURI.ItemShippingCost)) *
  100;
