import type { AdItem } from '@sbt-web/network/types';
import { getAdId } from '@sbt-web/utils';

import { getOrCreatePulse } from '@tools/tracking/utils';

export const socialLinkSendClick = (item: AdItem, platform: string): void => {
  const adId = getAdId(item?.urn);

  if (!adId) {
    return;
  }

  getOrCreatePulse()?.queueEvent({
    type: 'Engagement',
    name: 'Click on social link',
    object: {
      type: 'UIElement',
      id: `sdrn:subito:classified:${adId}`,
      label: platform,
    },
  });
};
