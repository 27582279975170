import { buildAdDetailTarget } from '@sbt-web/tracking';
import type { AdItem } from '@sbt-web/network/types';
import { getAdId } from '@sbt-web/utils';

import { getOrCreatePulse } from '@tools/tracking/utils';

export const adsOnlineLinkClick = (item: AdItem): void => {
  const adId = getAdId(item.urn);

  getOrCreatePulse()?.queueEvent({
    type: 'Engagement',
    object: {
      '@type': 'UIElement',
      '@id': `sdrn:subito:classified:${adId}:element:click-on-active_ads-advertiser-AD-Detail`,
    },
  });
};

export const adsPublishedDialogView = (item: AdItem): void => {
  const adId = getAdId(item.urn);
  const { object: ClassifiedAd } = buildAdDetailTarget(item);

  getOrCreatePulse()?.queueEvent({
    type: 'Engagement',
    object: {
      type: 'UIElement',
      id: 'click-on-socialization-annunci-pubblicati',
    },
    target: {
      ...ClassifiedAd,
      id: adId,
    },
  });
};
