import { type AdItem, CategoryID } from '@sbt-web/network/types';
import { isPro } from '@shared/models/Advertiser';
import { getTransactionFlows, TransactionFlow } from '@tools/transaction/utils';

const showPhoneCategories: CategoryID[] = [
  CategoryID.AccessoriAuto,
  CategoryID.Elettronica,
  CategoryID.Informatica,
  CategoryID.AudioVideo,
  CategoryID.Telefonia,
  CategoryID.ArredamentoCasalinghi,
  CategoryID.GiardinoFaiDaTe,
  CategoryID.AbbigliamentoAccessori,
  CategoryID.TuttoBambini,
  CategoryID.SportsHobby,
  CategoryID.MusicaFilm,
  CategoryID.Sports,
  CategoryID.Collezionismo,
  CategoryID.Altri,
  CategoryID.AltriAnnunci,
  CategoryID.AccessoriMoto,
  CategoryID.Elettrodomestici,
  CategoryID.LibriRiviste,
  CategoryID.StrumentiMusicali,
  CategoryID.Fotografia,
  CategoryID.Biciclette,
  CategoryID.ConsoleVideogiochi,
  CategoryID.AccessoriAnimali,
];

const isPhoneProtectionEnabled = (categoryId: CategoryID): boolean =>
  showPhoneCategories.includes(categoryId);

/**
 * @description Return true if advertiser is pro without transactions and has a phone number, or if not protected and has a phone number
 */
export const getShowPhone = (item: AdItem, userId?: string | null): boolean => {
  const transactionFlows = getTransactionFlows(item, userId);

  return isPro(item.advertiser.type)
    ? !!item.advertiser.phone &&
        !transactionFlows.includes(TransactionFlow.BuyNow)
    : !isPhoneProtectionEnabled(item.category.id) && !!item.advertiser.phone;
};
