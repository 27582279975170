import { Feature } from '@client/components/Detail/FeatureList';
import type { CarInfo } from '@sbt-web/network/types';

export function removeInfoCarDuplication(
  infocarData: CarInfo | null,
  features: Feature[]
): CarInfo | null {
  if (!infocarData) {
    return null;
  }
  const existingFeatures = new Set(
    Object.values(features).map((feature) => feature.uri)
  );

  return infocarData
    .map((info) => ({
      title: info.title,
      features: info.features.filter(
        (feature) => !existingFeatures.has(normalizeUri(feature.uri))
      ),
    }))
    .filter((info) => info.features.length > 0);
}

// infocar data has full uri, example /car/brand, /car/model, /car/version
// features has packed uri, example /car
// we can remove duplication by normalizing the uri
function normalizeUri(uri: string): string {
  return uri.split('/').slice(0, 2).join('/');
}
