import React from 'react';
import { Button, Icon, IconSize } from '@sbt-web/ui';
import { AdReplyEvent } from '../../../events';

import classes from '../index.module.scss';

export type ContactButtonProps = {
  isSecondary?: boolean;
  showLabel?: boolean;
  showIcon?: boolean;
};

export const ContactButton = ({
  isSecondary = false,
  showLabel = true,
  showIcon,
}: ContactButtonProps) => (
  <Button
    aria-label={showLabel ? undefined : 'Contatta'}
    design={isSecondary ? 'outline' : undefined}
    onClick={() => {
      window.dispatchEvent(new CustomEvent(AdReplyEvent.ContactButtonClick));
    }}
    classes={[classes.contactButton]}
    icon={showIcon ? <Icon name="Mail" iconSize={IconSize.MD} /> : undefined}
    size="large"
  >
    {showLabel && 'Contatta'}
  </Button>
);
