import React, { useContext, useEffect, useState } from 'react';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { LiraAdvContainer } from '@sbt-web/adv';
import gridStyles from '@client/components/Detail/Containers/style/grid.module.scss';
import classnames from 'classnames';

let alreadyFetched = false;

export const LiraLazySlotVehicleHistory = ({
  cssClass,
}: {
  cssClass?: string;
}) => {
  const { optimizely } = useContext(OptimizelySubitoContext);
  const [carfaxVariant, setCarfaxVariant] = useState<string>('A');

  useEffect(() => {
    optimizely?.onReady().then(() => {
      const variant = optimizely?.activate('subito-web-carfax-addetail');
      setCarfaxVariant(variant ?? 'A');
    });
  }, [optimizely]);

  useEffect(() => {
    if (!alreadyFetched && carfaxVariant !== 'A') {
      alreadyFetched = true;
      window.liraSDKQueue?.push(() => {
        window.liraSdk.fetchLazyLoadAd(
          [LiraContainerIds.adView.vehicleHistory],
          `subito-web-carfax-addetail-${carfaxVariant}`
        );
      });
    }
  }, [carfaxVariant]);

  return (
    carfaxVariant !== 'A' && (
      <div style={{ minHeight: `100px` }}>
        <LiraAdvContainer
          className={classnames(gridStyles['detail-component'], cssClass)}
          idForAd={LiraContainerIds.adView.vehicleHistory}
        />
      </div>
    )
  );
};
