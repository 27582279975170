import type { AdItem, TrustProfileInfo } from '@sbt-web/network/types';
import type { ProfileQueryData } from '@sbt-web/networking';
import { DEFAULT_ADVERTISER_NAME } from '@shared/constants';
import React from 'react';

import { AdPrivateBox } from '@client/components/Detail/AdPrivateBox';

export interface AdvertiserInfoPrivateProps {
  item: AdItem;
  advertiserProfile: ProfileQueryData | null;
  trustInfo: TrustProfileInfo | null;
  showContactButton?: boolean;
}

export const AdvertiserInfoPrivate = ({
  item,
  advertiserProfile,
  trustInfo,
  showContactButton,
}: AdvertiserInfoPrivateProps) => (
  <AdPrivateBox
    name={advertiserProfile?.username ?? DEFAULT_ADVERTISER_NAME}
    picture={advertiserProfile?.picture}
    score={trustInfo?.reputation.feedback?.sourceScores.MEMBER}
    presenceStatus={
      trustInfo?.presence.enabled
        ? (trustInfo?.presence.status ?? undefined)
        : undefined
    }
    presenceText={
      trustInfo?.presence.enabled
        ? trustInfo?.presence.presenceText || undefined
        : undefined
    }
    reviewCount={trustInfo?.reputation.feedback?.sourceCounts?.MEMBER}
    dealsDone={trustInfo?.reputation.feedback?.receivedCount}
    item={item}
    showContactButton={showContactButton}
  />
);
