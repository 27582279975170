import React from 'react';
import { Subhead, Icon, IconSize } from '@sbt-web/ui';
import { ShopSocialProps, SocialLink } from './types';
import { socialLinkSendClick } from './tracking';
import classes from './index.module.scss';

export const ShopSocial = ({ item, social = {} }: ShopSocialProps) => {
  const { facebook, instagram, linkedin } = social;

  const socialLinks: SocialLink[] = [
    { platform: 'Facebook', url: facebook },
    { platform: 'Instagram', url: instagram },
    { platform: 'LinkedIn', url: linkedin },
  ].filter((link): link is SocialLink => !!link.url);

  if (socialLinks.length === 0) {
    return null;
  }

  return (
    <div className={classes.social}>
      <Subhead>Contatti</Subhead>

      <div className={classes.social__links}>
        {socialLinks.map((link) => (
          <a
            href={link.url}
            key={link.platform}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Visita la pagina ${link.platform}`}
            onClick={() => socialLinkSendClick(item, link.platform)}
          >
            <Icon
              name={link.platform}
              fillColor="currentColor"
              iconSize={IconSize.MD}
              accessibleTitle={`Logo di ${link.platform}`}
            />
          </a>
        ))}
      </div>
    </div>
  );
};
