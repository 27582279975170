import React from 'react';
import { PhoneButton } from '../../../AdReply/components/AdReplyButtons/PhoneButton';
import { useViewport } from '@sbt-web/hooks';
import type { AdItem } from '@sbt-web/network/types';
import classes from './index.module.scss';
import {
  PHONE_NUMBER_PLACEHOLDER,
  MOBILE_LABEL,
  DESKTOP_LABEL,
} from './config';

interface ReplacePhoneNumberWithButtonProps {
  ad: AdItem;
}

export const ReplacePhoneNumberWithButton = ({
  ad,
}: ReplacePhoneNumberWithButtonProps) => {
  const { isMobile } = useViewport();
  const parts = ad.body.split(PHONE_NUMBER_PLACEHOLDER);
  const partsWithKeys = parts.map((part, index) => ({
    content: part,
    key: `${ad.urn}-${index}`,
  }));

  return partsWithKeys.map((part, index) => (
    <React.Fragment key={part.key}>
      {part.content}
      {index < parts.length - 1 && (
        <PhoneButton
          isMobile={isMobile}
          adUrn={ad.urn}
          item={ad}
          buttonDesign="text"
          buttonSize="medium"
          buttonLabelDesktop={DESKTOP_LABEL}
          buttonLabelMobile={MOBILE_LABEL}
          buttonClass={classes['show-number']}
          pulseEvent={{
            name: 'Ad Description',
            pageType: 'ad_detail',
          }}
        />
      )}
    </React.Fragment>
  ));
};
