import React from 'react';
import { SendOfferButton } from './SendOfferButton';
import { BuyNowButton } from './BuyNowButton';
import {
  getTransactionFlows,
  TransactionFlow,
} from '@shared/tools/transaction/utils';
import type { AdItem } from '@sbt-web/network/types';

export interface AdBuyButtonsProps {
  onBuyNowButtonClick: () => void;
  onSendOfferButtonClick: () => void;
  item: AdItem;
  userId?: string;
  buyNowButtonClasses?: string[];
  sendOfferButtonClasses?: string[];
}

export const AdBuyButtons = ({
  onBuyNowButtonClick,
  onSendOfferButtonClick,
  item,
  userId,
  buyNowButtonClasses,
  sendOfferButtonClasses,
}: AdBuyButtonsProps) => {
  const transactionFlows = getTransactionFlows(item, userId);
  const isBuyNowVisible = transactionFlows.includes(TransactionFlow.BuyNow);
  const isSendOfferVisible = transactionFlows.includes(
    TransactionFlow.SendOffer
  );

  if (!isBuyNowVisible && !isSendOfferVisible) {
    return;
  }

  return (
    <>
      {isSendOfferVisible && (
        <SendOfferButton
          onClick={onSendOfferButtonClick}
          isSecondary={isBuyNowVisible}
          classes={sendOfferButtonClasses}
        />
      )}
      {isBuyNowVisible && (
        <BuyNowButton
          onClick={onBuyNowButtonClick}
          classes={buyNowButtonClasses}
        />
      )}
    </>
  );
};
