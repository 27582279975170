import { CardBlock } from '@sbt-web/ancillary-services';
import { useViewport } from '@sbt-web/hooks';
import { type AdItem, FeatureURI } from '@sbt-web/network/types';
import { HebeCampaignPosition } from '@sbt-web/networking';
import { Headline6 } from '@sbt-web/ui';
import { prepareRemoteDataAdDetail } from '@shared/helpers/Hebe';
import { getOrCreatePulse } from '@tools/tracking/utils';
import classnames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import FeatureList, { Feature } from '../../FeatureList';
import type { SectionProps } from './section';
import ownClasses from './style/vehicle-history-section.module.scss';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { LiraLazySlotVehicleHistory } from '@client/components/Adv/Lira/LiraLazySlotVehicleHistory';

type Props = SectionProps & {
  features: Feature[];
  adItem: AdItem;
};

export function VehicleHistorySection({
  features,
  classes = [],
  adItem,
}: Props) {
  const { isMobile } = useViewport();
  const { optimizely } = useContext(OptimizelySubitoContext);
  const [carfaxVariant, setCarfaxVariant] = useState<string>('A');

  const showCarfax = useCallback((): boolean => {
    const mileageFeature =
      adItem?.features[FeatureURI.MileageScalar]?.values[0].key;
    const yearFeature = adItem?.features[FeatureURI.Year]?.values[0].key;
    if (!mileageFeature || !yearFeature) return false;

    const mileage = parseInt(mileageFeature);
    const year = parseInt(yearFeature);
    return mileage >= 40000 && mileage <= 499999 && year < 2020;
  }, [adItem.features]);

  useEffect(() => {
    if (showCarfax()) {
      optimizely?.onReady().then(() => {
        const variant = optimizely?.activate('subito-web-carfax-addetail');
        setCarfaxVariant(variant ?? 'A');
      });
    }
  }, [optimizely, showCarfax]);

  return (
    <section className={classnames(...classes)}>
      <Headline6 classes={[ownClasses['vehicle-history-title']]} element="h2">
        Storia del veicolo
      </Headline6>
      {showCarfax() && (
        <LiraLazySlotVehicleHistory
          cssClass={ownClasses['vehicle-history-adv']}
        />
      )}
      <FeatureList features={features} />
      {carfaxVariant !== 'C' && (
        <CardBlock
          pulseInstance={getOrCreatePulse()}
          background
          bannerButtonDesign="outline"
          item={adItem}
          remoteData={prepareRemoteDataAdDetail(
            HebeCampaignPosition.BELOW_HISTORY,
            adItem,
            isMobile
          )}
          classes={[ownClasses['vehicle-history-container']]}
        />
      )}
    </section>
  );
}

export default VehicleHistorySection;
