import classNames from 'classnames';
import React from 'react';
import { LiraLazySlotBelowAdView } from '@client/components/Adv/Lira/LiraLazySlotBelowAdView';

const LiraContainer = () => {
  return <LiraLazySlotBelowAdView />;
};

export const AdsAdDetailsBlock = function ({
  isMobile,
  className,
}: {
  isMobile?: boolean;
  className?: string;
}) {
  return isMobile ? (
    <LiraContainer />
  ) : (
    <div
      className={classNames('adsense-bottom-container', className)}
      style={{ margin: '20px', width: '620px' }}
    >
      <LiraContainer />
    </div>
  );
};
