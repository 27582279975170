import React from 'react';
import { extractUtmFromLocation, getAdId } from '@sbt-web/utils';
import { Button } from '@sbt-web/ui';
import { trackIncreaseVisibilityClick } from './tracking';
import styles from './index.module.scss';

type IncreaseVisibilityButtonProps = {
  isMobile: boolean;
  adUrn: string;
};

export const getPromoteURL = (adUrn: string): string => {
  const queryParams = extractUtmFromLocation();
  queryParams.append('entry_point', 'ADDETAIL_PROMOTE');

  return new URL(
    `${process.env.NEXT_PUBLIC_AREARISERVATA_BASE_URL}/annunci/promuovi/${adUrn}?${queryParams.toString()}`
  ).toString();
};

export const IncreaseVisibilityButton = ({
  isMobile,
  adUrn,
}: IncreaseVisibilityButtonProps) => {
  const handleClick = async () => {
    const adID = getAdId(adUrn);
    if (adID) trackIncreaseVisibilityClick(adID);

    window.location.assign(getPromoteURL(adUrn));
  };

  return (
    <Button
      onClick={handleClick}
      classes={[styles.increaseVisibilityButton]}
      size={isMobile ? 'large' : 'medium'}
    >
      Aumenta visibilità
    </Button>
  );
};
